import AboutOne from '../components/about/whoWeAre';
import Footer from '../components/common/footer';
import Layout from '../components/common/layout';
import NavigationMenu from '../components/common/navigationMenu';
import OurPeople from '../components/about/ourPeople';
import PageHeader from '../components/common/pageHeader';
import React from 'react';

const AboutPage = () => {
  return (
    <Layout pageTitle='Avadhani & Associates | About'>
      <NavigationMenu active='about' />
      <PageHeader title='About' />
      <AboutOne />
      <OurPeople />
      <Footer />
    </Layout>
  );
};

export default AboutPage;
